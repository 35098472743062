<template>
  <b-container fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side"> </b-col>
      <b-col cols="12" md="6" class="right-side">
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <span class="logo mb-5">
            <img src="@/assets/img/logo.svg" />
          </span>

          <h4 class="title">{{ $t("views.seller.login.text_1570")}}</h4>
          <p class="sub-title">{{ $t("views.seller.login.text_1571")}}</p>
          
          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group
              :label="$t('views.seller.login.text_1556')"
              label-for="email"
            >
              <b-form-input
                id="email"
                :placeholder="$t('views.seller.login.text_1557')"
                v-model="email"
                type="email"
                name="email"
                @input="checkFormValidity"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('email')">
                {{ $t("views.seller.login.text_1558") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('views.seller.login.text_1559')"
              label-for="password"
            >
              <b-form-input
                id="password"
                :placeholder="$t('views.seller.login.text_1572')"
                v-model="password"
                type="password"
                name="password"
                @input="checkFormValidity"
                v-validate="'required'"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                > </span
              >
              <b-form-invalid-feedback :state="!errors.has('password')">
                {{ $t("views.seller.login.text_1561") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-invalid-feedback :state="success">
              {{ $t("views.seller.login.text_1562") }}
            </b-form-invalid-feedback>

            <b-row class="justify-content-end mt-3">
              <router-link
                :to="{ name: 'Seller-Password-Recovery' }"
                class="recupera-senha-text"
                >{{ $t("views.seller.login.text_1563") }}
              </router-link>
            </b-row>

            <b-row class="mt-4 btn-container">
              
              <Captcha ref="Captcha" @changeVerify="changeVerify">
                  <BaseButton
                    variant="secondary"
                    ref="button"
                    :disabled="loading || !isFormValid"
                    type="submit"
                    v-if="!loginClub && !loginEnvios"
                    class="btn-login"
                    @click="onSubmit"
                  >
                    {{ $t("views.seller.login.text_1565") }}
                  </BaseButton>

                  <BaseButton
                    variant="secondary"
                    v-else
                    ref="button"
                    :disabled="loading"
                    @click="externalSubmit"
                    class="btn-login"
                  >
                    {{ $t("views.seller.login.text_1565") }}
                  </BaseButton>
                </Captcha>
            </b-row>

            <b-row class="mt-4 linha-possui-conta">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
               {{ $t("views.seller.login.text_1569") }} <router-link
                  :to="{ name: 'Seller-Register' }"
                  class="recupera-senha-text"
                  >{{ $t("views.seller.login.text_1564") }}</router-link
                >
                <img src="~@/assets/img/icons/long-arrow-orange.svg" />
              </b-overlay>
            </b-row>
          </b-form>
        </div>

        <div class="termos-privacidade">
              <a
                class="links-help mr-1"
                href="https://www.voompcreators.com.br/termos-e-documentacoes/"
                target="_blank"
                >{{ $t("views.seller.login.text_1568") }}</a
              >
              <span class="links-help mr-1">|</span>
              <a
                class="links-help"
                href="https://www.canaldatransparencia.com.br/cogna/"
                target="_blank"
                >{{ $t("views.seller.login.text_1566") }}</a
              >
        </div>
        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import AuthServiceClub from "@/services/resources/AuthServiceClub";
import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";
const serviceClub = AuthServiceClub.build();
const serviceEnvios = AuthServiceEnvios.build();
import Captcha from "@/components/security/Captcha.vue";

export default {
  name: "Login",
  components: {
    Captcha,
  },
  data() {
    return {
      email: "",
      password: "",
      success: true,
      loading: false,
      loginClub: false,
      loginEnvios: false,
      urlClub: "",
      showPassword: false,
      hash: null,
      id_product: null,
      errorCaptch: false,
      captcha: false,
      manager: null,
      isFormValid: false
    };
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      if (this.loginClub || this.loginEnvios) {
        this.externalSubmit("get");
        return;
      }
      this.onSubmit("get");
    },
    checkFormValidity() {
      this.isFormValid = this.email !== '' && this.password !== '';
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
      } else {
        input.setAttribute("type", "password");
      }
    },
    existClubParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const url = urlParams.get("redirectTo");
      if (type === undefined || type === "") {
        this.loginClub = false;
        this.loginEnvios = false;
      } else if (type === "club") {
        this.loginClub = true;
        if (url !== undefined && url !== "") {
          this.urlClub = url;
        }
      } else if (type === "envios") {
        this.loginEnvios = true;
      }
    },
    externalSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.success = true;

      var service = "";
      if (this.loginClub) {
        service = serviceClub;
      } else {
        service = serviceEnvios;
      }

      service
        .login(this.email, this.password, this.captcha)
        .then((resp) => {
          if (resp.string) {
            if (this.loginClub) {
              if (
                this.urlClub !== "" &&
                this.urlClub !== null &&
                this.urlClub !== undefined
              ) {
                window.location.href =
                  "http://" +
                  this.urlClub +
                  "?rdr=" +
                  resp.string +
                  "&type=greenn";
              } else {
                window.location.href =
                  process.env.VUE_CLUB_API_HOST +
                  "?rdr=" +
                  resp.string +
                  "&type=greenn";
              }
            } else if (this.loginEnvios) {
              window.location.href =
                process.env.VUE_ENVIOS_API_HOST +
                "/login?rdr=" +
                resp.string +
                "&type=greenn";
            }
          } else {
            this.success = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    onSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.success = true;
          this.loading = true;
          let data = {
            username: this.email,
            password: this.password,
            captcha: this.captcha,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then(() => {
              if (this.hash) {
                this.$router.push(
                  `/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`
                );
              } else {
                this.$router.push(`/verificar-email${window.location.search}`);
              }
            })
            .catch((err) => {
              console.log(err);
              this.$refs.Captcha.resetCaptcha();
            })
            .finally(() => {
              this.success = false;
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },

    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
  updated() {
    const crisp = document.querySelector(".crisp-client");
    if (crisp) {
      setTimeout(() => {
        crisp.style.display = "none";
      });
    }
  },

  mounted() {
    this.existClubParams();

    if (typeof this.$route.query.hash_a != "undefined") {
      this.hash = this.$route.query.hash_a;
    }
    if (typeof this.$route.query.p_id != "undefined") {
      this.id_product = this.$route.query.p_id;
    }
    if (typeof this.$route.query.manager != "undefined") {
      this.manager = this.$route.query.manager;
    }

    if (typeof this.$route.query.email != "undefined") {
      this.email = this.$route.query.email;
      const input = document.querySelector("#password");
      if (input) {
        input.focus();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}

.bg-login {
  background: $base-color; 
}
.title{
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}
.button:disabled {
    color: #E6E9EC;
    background-color: #9AA3B0!important;
}

input[type="text"], input[type="password"], input[type="email"]{
  height: 53px!important;
  padding: 16px 24px 16px 24px!important;
  border-radius: 4px!important;
  border: 1px solid #DEDEDE!important;
  
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 21px!important;
  letter-spacing: 0em!important;
  text-align: left!important;

}

label{
  font-weight: 600!important;
  margin-bottom: 4px !important;
  font-size: 16px!important;
  line-height: 24px!important;
}

.sub-title{
    margin-top: 15px;
    margin-bottom: 45px;
    font-size: 14px;
}

.btn-container{
  display: block;
}
.btn-login{
    background-color: #0a1e3e;
    height: 49px;
    border: 0;
    font-size: 14px;
    width: 100%;
}
.linha-possui-conta{
      width: 100%;
      display: inline-flex;
      font-size: 14px;
      color: #575757;
}
.linha-possui-conta a{
      margin-left: 5px;
}
.linha-possui-conta img{
  transform: rotate(180deg);
  height: 9px;
  margin-left: 5px;
}

.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 25px;
}

.left-side {
  background-image: url("~@/assets/img/login-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

input[type=password]::placeholder , input[type=text]::placeholder , input[type=email]::placeholder {
    font-size: 14px !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 21px!important;
    letter-spacing: 0em!important;
    text-align: left!important;
}

.right-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;
  right: 0;
  position: absolute;

  .inner-wrapper {
    padding: 6% 18%;

    .logo {
      img {
        width: 143.83px;
        margin-bottom: 45px;
      }
    }
  }
}
.recupera-senha-text {
  color: #ff9220;;
  font-size: 14px;
}
.links-help {
  font-size: 14px;
  color: #81858e;
  margin-top: 20px;
}
.termos-privacidade{
    bottom: 15px; 
    text-align: center;
    font-size: 14px;
    width: 100%;
    text-align: center;
  margin-top: 40px;
}
.btn-acoes {
  width: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mostrar-senha {
    font-size: 13px;
    background: rgb(255 255 255 / 3%) url(/img/eye-off.232e5a7d.svg);
    position: absolute;
    top: 55%;
    right: 20px;
    cursor: pointer;
    content: "";
    padding: 8px;
}

@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
  .logo img {
    width: 138.83px !important;
  }
  .links-help {
    margin: 0;
  }
}
</style>
